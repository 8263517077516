<template>
  <div>
    <div class="columns">
      <div class="column">
        <h2 class="title is-3">Список доменов ({{ domains.length }})</h2>
      </div>
      <div class="column">
        <b-button
          class="is-pulled-right"
          type="is-primary"
          icon-right="plus"
          @click="createDomain()"
        >Добавить домен</b-button
        >
      </div>
    </div>
    <div style="margin-top: 60px;">
      <div class="draggable-item" v-for="item in domains" :key="item.id">
        <div class="level">
          <div class="level-item has-text-centered">
            <div>
              <p class="title is-4">{{ item.name }}</p>
            </div>
          </div>
          <div class="level-item has-text-centered" style="float: right">
            <div>
              <v-row>
                <v-col v-if="authUser.role === 'admin' || authUser.role === 'superuser'">
                  <b-tooltip label="Удалить домен">
                    <b-button
                      type="is-danger"
                      icon-right="delete"
                      @click="deleteDomain(item)"
                    >
                    </b-button>
                  </b-tooltip>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DomainDetailsModal></DomainDetailsModal>
    <ConfirmDeletionModal></ConfirmDeletionModal>
  </div>
</template>

<script>
import DomainDetailsModal from '@/app/client/domains/shared/modals/domainDetailsModal.vue';
import ConfirmDeletionModal from '@/app/client/domains/shared/modals/confirmDeletionDomainModal.vue';

export default {
  name: "DomainsList",
  components: {
    DomainDetailsModal,
    ConfirmDeletionModal,
  },
  data() {
    return {
    };
  },
  created() {
    this.$store.dispatch("GET_DOMAINS");
  },
  computed: {
    authUser() {
      return this.$store.getters.AUTH_USER;
    },
    domains() {
      return this.$store.getters.DOMAINS;
    },
  },
  methods: {
    createDomain() {
      this.$store.dispatch("DOMAIN_DETAILS_MODAL", true);
    },
    deleteDomain(domain) {
      this.$store.dispatch("CURRENT_DOMAIN", domain);
      this.$store.dispatch("CONFIRM_DELETION_DOMAIN_MODAL", true);
    },
  },
};
</script>

<style>
.draggable-item {
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 10px;
  background-color: #fff;
}
</style>
